<template>
  <div>
    <div>
      <div>
        <b-row>
          <b-col
            cols="7"
            class="pr-0"
          >
            <b-card>
              <b-row class="container testimonial-group">
                <b-col>
                  <b-card class="mb-1">
                    <b-row class="scroll3">
                      <b-col
                        v-for="menuType in menuTypes"
                        :key="menuType.id"
                        md="3"
                      >
                        <b-link @click="setMenuGroup(menuType.menuGroups)">
                          <b-card
                            overlay
                            body-class="bg-gradient-secondary"
                            text-variant="white"
                            class="text-center mb-1"
                            style="height: 100px; width: 100%"
                          >
                            <b-card-title class="text-white">
                              {{ menuType.name }}
                            </b-card-title>
                          </b-card>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-row class="scroll">
                <b-col>
                  <b-card
                    v-for="menuGroup in menuGroups"
                    :key="menuGroup.id"
                    :title="menuGroup.name"
                    class="mb-1"
                  >
                    <b-row>
                      <b-col
                        v-for="menu in menuGroup.menus"
                        :key="menu.id"
                        md="3"
                      >
                        <b-link
                          v-b-modal.modal-menu-add
                          @click="openMenuModal(menu)"
                        >
                          <b-card
                            overlay
                            body-class="bg-gradient-secondary"
                            text-variant="white"
                            class="text-center mb-1"
                            style="height: 100px; width: 100%;"
                          >
                            <b-card-title class="text-white">
                              {{ menu.name }}
                            </b-card-title>
                          </b-card>
                        </b-link>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
            <!-- <OrderCompPaneLeft/> -->
          </b-col>

          <b-col>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col cols="5">
                    <h3 class="text-primary mb-1">
                      Table :  {{ tableName }}
                    </h3>
                    <b-button
                      v-if="order_status == 'pending'"
                      variant="danger"
                      @click="cancelOrder"
                    >
                      <feather-icon
                        icon="DeleteIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Cancel Order</span>
                    </b-button>
                    <b-button
                      v-if="order_status == 'completed'"
                      variant="danger"
                      @click="cancelOrder"
                    >
                      <feather-icon
                        icon="DeleteIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Void Order</span>
                    </b-button>
                  </b-col>

                  <b-col>
                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Bill no."
                          label-for="billNo"
                          label-cols-md="4"
                        >
                          <b-form-input
                            id="billNo"
                            v-model="billNo"
                            plaintext
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group
                          label="Open Time"
                          label-for="billDateTime"
                          label-cols-md="4"
                        >
                          <b-form-input
                            id="billDateTime"
                            v-model="billDateTime"
                            plaintext
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <!-- Spacer -->
                <!-- <hr class="invoice-spacing"> -->

                <b-row>
                  <b-col>
                    <b-form-group
                      label="ลูกค้า"
                      label-for="customer"
                      label-cols-md="2"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-button
                            variant="outline-primary"
                            @click="updateCustomer"
                          >
                            <feather-icon icon="PlusIcon" />
                            Add
                          </b-button>
                        </b-input-group-prepend>
                        <b-form-input
                          id="customer"
                          v-model="customer"
                        />
                        <b-input-group-append>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            @click="searchCustomer"
                          >
                            <feather-icon icon="SearchIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <!-- <hr class="invoice-spacing"> -->

              <!-- Items Section -->
              <b-card-body class="invoice-padding form-item-section scroll2">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in orders"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <!-- <div class="d-none d-lg-flex">
                    <b-row class="flex-grow-1 px-1">
                      <b-col
                        cols="12"
                        lg="5"
                      >
                        Item
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                      >
                        Cost
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                      >
                        Qty
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                      >
                        Price
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div> -->

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <b-col>
                            <b-row>
                              <b-col
                                cols="12"
                                lg="5"
                              >
                                <!-- <label class="d-inline d-lg-none">Item</label> -->
                                <h5 class="mb-1">
                                  {{ item.menuName }}
                                </h5>
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <!-- <label class="d-inline d-lg-none">Qty</label> -->
                                <p class="mb-1">
                                  {{ item.qty }}
                                </p>
                              </b-col>
                              <b-col
                                cols="12"
                                lg="3"
                              >
                                <!-- <label class="d-inline d-lg-none">Cost</label> -->
                                <p class="mb-1">
                                  {{ item.price }}
                                </p>
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <!-- <label class="d-inline d-lg-none">Price</label> -->
                                <p class="mb-1">
                                  {{ item.totPrice }}
                                </p>
                              </b-col>
                            </b-row>

                            <b-row>
                              <b-col cols="12">
                                <p>
                                  {{ getAttribute(item.attributeSelect) }}
                                </p>
                              </b-col>
                            </b-row>

                            <div
                              v-for="addOnItem in item.addOns"
                              :key="addOnItem.id"
                            >
                              <b-row>
                                <b-col
                                  cols="12"
                                  lg="5"
                                >
                                  <p>
                                    - {{ addOnItem.name }}
                                  </p>
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="2"
                                >
                                  <p>
                                    {{ addOnItem.qty }}
                                  </p>
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="3"
                                >
                                  <p>
                                    {{ addOnItem.price }}
                                  </p>
                                </b-col>
                                <b-col
                                  cols="12"
                                  lg="2"
                                >
                                  <p>
                                    {{ addOnItem.qty * addOnItem.price }}
                                  </p>
                                </b-col>
                              </b-row>
                            </div>

                            <b-row v-if="item.discount > 0">
                              <b-col
                                cols="10"
                                class="text-success"
                              >
                                - Discount
                              </b-col>
                              <b-col
                                cols="2"
                                class="text-success"
                              >
                                {{ item.discount }}
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            :id="`form-item-settings-icon-${index}`"
                            size="16"
                            icon="BellIcon"
                            :class="`cursor-pointer ${item.isFollowUp ? 'text-warning' : 'text-secondary'}`"
                            @click="followOrderItem(item)"
                          />
                          <feather-icon
                            size="16"
                            icon="Trash2Icon"
                            class="cursor-pointer"
                            @click="removeItem(index, item)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Total -->
              <b-card-body class="invoice-padding pt-0 pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <!-- <p class="invoice-total-title"> -->
                        Total Before Discount :
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item">
                        <!-- <p class="invoice-total-title text-success"> -->
                        <p class="text-success">
                          Discount :
                        </p>
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item">
                        <!-- <p class="invoice-total-title"> -->
                        Total After Discount :
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item text-danger">
                        <!-- <p class="invoice-total-title text-danger"> -->
                        <!-- <p class="text-danger"> -->
                        Services Charge (10%) :
                        <!-- </p>   -->
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item text-danger">
                        <!-- <p class="invoice-total-title text-danger"> -->
                        <!-- <p class="text-danger"> -->
                        <p>
                          VAT (7%) :
                        </p>
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item">
                        <!-- <p class="invoice-total-title"> -->
                        Total Before Voucher :
                        <!-- </p> -->
                      </div>
                      <div class="invoice-total-item">
                        <!-- <p class="invoice-total-title text-success"> -->
                        <p class="text-success">
                          Voucher :
                        </p>
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item">
                        <h5 class="invoice-total-title">
                          Grand Total :
                        </h5>
                      </div>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item invoice-total-amount text-right">
                        <!-- <p class="invoice-total-amount text-right"> -->
                        {{ FormatNumber(totalBeforeDiscount) }}
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item invoice-total-amount text-right text-success">
                        <!-- <p class="invoice-total-amount text-right text-success"> -->
                        <p>
                          {{ FormatNumber(discount) }}
                          <!-- </p> -->
                        </p>
                      </div>

                      <div class="invoice-total-item invoice-total-amount text-right">
                        <!-- <p class="invoice-total-amount text-right"> -->
                        {{ FormatNumber(totalAfterDiscount) }}
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item invoice-total-amount text-right text-danger">
                        <!-- <p class="invoice-total-amount text-right text-danger"> -->
                        {{ FormatNumber(serviceCharge) }}
                        <!-- </p> -->
                      </div>

                      <div class="invoice-total-item invoice-total-amount text-right text-danger">
                        <!-- <p class="invoice-total-amount text-right text-danger"> -->
                        <p>
                          {{ FormatNumber(vat) }}
                        </p>
                      <!-- </p> -->
                      </div>

                      <div class="invoice-total-item invoice-total-amount text-right">
                        <!-- <p class="invoice-total-amount text-right"> -->
                        {{ FormatNumber(beforeVoucher) }}
                        <!-- </p> -->
                      </div>
                      <div class="invoice-total-item invoice-total-amount text-right text-success">
                        <!-- <p class="invoice-total-amount text-right text-success"> -->
                        <p>
                          {{ FormatNumber(voucher) }}
                          <!-- </p> -->
                        </p>
                      </div>

                      <div class="invoice-total-item invoice-total-amount text-right">
                        <h5 class="invoice-total-amount text-right">
                          {{ FormatNumber(grandTotal) }}
                        </h5>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Total -->
              <b-card-body class="invoice-padding pt-0 pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Recieved :
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title text-primary">
                          Changed :
                        </p>
                      </div>
                    </div>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-amount text-right">
                          {{ recieved.toFixed(2) }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-amount text-right text-primary">
                          {{ changed.toFixed(2) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- <b-card-footer class="text-center">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <feather-icon
                icon="SaveIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                บันทึก
              </p>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <feather-icon
                icon="CoffeeIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                สั่งอาหาร
              </p>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
              @click="printReceipt()"
            >
              <feather-icon
                icon="PrinterIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                ปริ้นท์บิล
              </p>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <feather-icon
                icon="InboxIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                ลิ้นชัก
              </p>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-discount
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <feather-icon
                icon="GiftIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                ส่วนลด
              </p>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-checkbill
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <feather-icon
                icon="CreditCardIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                เช็คบิล
              </p>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-ReportOrderSum
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <p class="mt-1">
                Order Summary
              </p>
            </b-button>
             <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
               v-b-modal.modal-ReportItemSum
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <p class="mt-1">
                Order Detail
              </p>
            </b-button>
          </b-card-footer> -->
            </b-card>
          </b-col>
        </b-row>
        <b-modal
          id="modal-menu-add"
          ok-only
          ok-title="Ok"
          @ok="addOrderItem"
        >
          <b-row>
            <b-col>
              <b-card
                overlay
                body-class="bg-gradient-secondary"
                text-variant="white"
                class="text-center mb-1"
                style="height: 100px; width: 100%;"
              >
                <b-card-title class="text-white">
                  {{ menuModel.menuName }}
                </b-card-title>
              </b-card>
            </b-col>
            <b-col>
              <label for="dqty">Please input Quantity</label>
              <b-form-spinbutton
                id="qty"
                v-model="menuModel.qty"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Attribute"
                label-for="attribute"
              >
                <v-select
                  id="attribute"
                  v-model="menuModel.attributeSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  taggable
                  push-tags
                  :options="menuModel.attributeOption"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="align-items-end">
            <b-col
              cols="11"
              class="mr-0"
            >
              <b-form-group
                label="Add-On"
                label-for="addOn"
              >
                <v-select
                  id="addOn"
                  v-model="menuModel.addOnSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :options="menuModel.addOnOption"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="1"
              class="ml-0 pl-0"
            >
              <b-form-group
                label=""
                label-for="btnAddOn"
              >
                <b-button
                  id="btnAddOn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-addOn
                  variant="outline-primary"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="align-items-end">
            <b-col>
              <b-form-group
                label="Price/Unit"
                label-for="price"
              >
                <b-button-group>
                  <b-form-radio-group
                    id="price"
                    v-model="menuModel.price"
                    button-variant="outline-primary"
                    :options="menuModel.prices"
                    buttons
                  />
                </b-button-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label=""
                label-for="specifyPrice"
              >
                <b-input-group
                  prepend="Custom Price"
                >
                  <cleave
                    id="specifyPrice"
                    v-model="menuModel.specifyPrice"
                    class="form-control text-right"
                    :options="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="align-items-end">
            <b-col>
              <b-form-group
                label="Special Discount"
                label-for="discount"
              >
                <b-input-group append="%">
                  <cleave
                    id="discount"
                    v-model="menuModel.discount"
                    class="form-control text-right"
                    :options="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group
                label=""
                label-for="isTakeHome"
              >
                <b-form-checkbox
                  id="isTakeHome"
                  v-model="menuModel.isTakeHome"
                >
                  Take-Away
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label="Remark"
                label-for="remark"
              >
                <b-form-input
                  id="remark"
                  v-model="menuModel.remark"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal
          id="modal-addOn"
          ok-only
          ok-title="Ok"
          @ok="handleOkAddOn"
        >
          <b-row>
            <b-col>
              <!-- <b-card
            overlay
            body-class="bg-gradient-secondary"
            text-variant="white"
            class="text-center mb-1"
            style="height: 100px; width: 100%;"
          >
            <b-card-title class="text-white">
              {{ addOnModel.name }}
            </b-card-title>
          </b-card> -->
              <b-form-group
                label="Add-On"
                label-for="addOnSelect"
              >
                <v-select
                  id="addOnSelect"
                  v-model="addOnModel.addOnSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="addOnOption"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="align-items-end">
            <b-col>
              <b-form-group
                label="โปรดระบุจำนวน"
                label-for="qtyAddOn"
              >
                <b-form-spinbutton
                  id="qtyAddOn"
                  v-model="addOnModel.qty"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="ราคา/หน่วย"
                label-for="priceAddOn"
              >
                <b-button-group>
                  <b-form-radio-group
                    id="priceAddOn"
                    v-model="addOnModel.price"
                    button-variant="outline-primary"
                    :options="addOnModel.prices"
                    buttons
                  />
                </b-button-group>
              </b-form-group>
            </b-col>

            <!-- <b-col>
          <b-form-group
            label=""
            label-for="specifyPriceAddOn"
          >
            <b-input-group
              prepend="ระบุเอง"
            >
              <cleave
                id="specifyPriceAddOn"
                v-model="addOnModel.specifyPrice"
                class="form-control text-right"
                :options="number"
              />
            </b-input-group>
          </b-form-group>
        </b-col> -->
          </b-row>
        </b-modal>

        <b-modal
          id="modal-discount"
          title="Discount"
          ok-only
          ok-title="Ok"
          @ok="updateDiscount"
        >
          <b-row>
            <b-col>
              <b-form-group
                label-cols="5"
                label="Voucher"
                label-for="voucher"
              >
                <v-select
                  id="voucher"
                  v-model="discountModel.voucherSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="voucherOption"
                />
                <!--
                <cleave
                  id="voucher_qty"
                  v-model="discountModel.voucherQty"
                  class="form-control text-right"
                  :options="number"
                />
                -->
                <cleave
                  id="voucher_qty"
                  v-model="discountModel.voucherQty"
                  class="form-control text-right"
                  :options="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="5"
                label="% Discount #1"
                label-for="discount1"
              >
                <v-select
                  id="discount1"
                  v-model="discountModel.discountSelect1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="discountOption1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="5"
                label="% Discount #2"
                label-for="discount2"
              >
                <v-select
                  id="discount2"
                  v-model="discountModel.discountSelect2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="discountOption2"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="5"
                label="% Discount #3"
                label-for="discount3"
              >
                <v-select
                  id="discount3"
                  v-model="discountModel.discountSelect3"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="discountOption3"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="5"
                label="ไม่ลดร่วมกับรายการโปรโมชั่น"
                label-for="isNotDiscountPromotion"
              >
                <b-form-checkbox
                  id="isNotDiscountPromotion"
                  v-model="discountModel.isNotDiscountPromotion"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal
          id="modal-checkbill"
          title="Payment"
          size="lg"
          ok-only
          ok-title="Ok"
          @ok="updatePayment"
        >
          <!-- <b-row>
        <b-col cols="4">
          <b-form-group
            label="ยอดเงินที่ต้องชำระ"
            label-for="totalPay"
          >
            <cleave
              id="totalPay"
              v-model="totalPay"
              class="form-control text-right"
              :options="number"
              disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row> -->
          <b-row>
            <b-col>
              <b-form-group
                label="Payment Type"
                label-for="paymentMethodSelect1"
              >
                <v-select
                  id="paymentMethodSelect1"
                  v-model="checkBillModel.paymentMethodSelect1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodOption"
                  @input="setCreditCharge1"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Payment Amount"
                label-for="amount1"
              >
                <cleave
                  id="amount1"
                  v-model="checkBillModel.amount1"
                  class="form-control text-right"
                  :options="number"
                  @input="calChanged1"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Change Amount"
                label-for="changed1"
              >
                <cleave
                  id="changed1"
                  v-model="checkBillModel.changed1"
                  class="form-control text-right"
                  :options="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="checkBillModel.isCredit1">
            <b-col>
              <b-form-group
                label-cols="2"
                label="Charge 3%"
                label-for="isCreditCharge1"
              >
                <b-form-checkbox
                  id="isCreditCharge1"
                  v-model="checkBillModel.isCreditCharge1"
                  @change="calCreditCharge1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Payment Type"
                label-for="paymentMethodSelect2"
              >
                <v-select
                  id="paymentMethodSelect2"
                  v-model="checkBillModel.paymentMethodSelect2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodOption"
                  @input="setCreditCharge2"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Payment Amount"
                label-for="amount2"
              >
                <cleave
                  id="amount2"
                  v-model="checkBillModel.amount2"
                  class="form-control text-right"
                  :options="number"
                  @input="calChanged2"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Chang Amount"
                label-for="changed2"
              >
                <cleave
                  id="changed2"
                  v-model="checkBillModel.changed2"
                  class="form-control text-right"
                  :options="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="checkBillModel.isCredit2">
            <b-col>
              <b-form-group
                label-cols="2"
                label="Charge 3%"
                label-for="isCreditCharge2"
              >
                <b-form-checkbox
                  id="isCreditCharge2"
                  v-model="checkBillModel.isCreditCharge2"
                  @change="calCreditCharge2"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Payment Type"
                label-for="paymentMethodSelect3"
              >
                <v-select
                  id="paymentMethodSelect3"
                  v-model="checkBillModel.paymentMethodSelect3"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paymentMethodOption"
                  @input="setCreditCharge3"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Payment Amount"
                label-for="amount3"
              >
                <cleave
                  id="amount3"
                  v-model="checkBillModel.amount3"
                  class="form-control text-right"
                  :options="number"
                  @input="calChanged3"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Change Amount"
                label-for="changed3"
              >
                <cleave
                  id="Change Payment"
                  v-model="checkBillModel.changed3"
                  class="form-control text-right"
                  :options="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="checkBillModel.isCredit3">
            <b-col>
              <b-form-group
                label-cols="2"
                label="Charge 3%"
                label-for="isCreditCharge3"
              >
                <b-form-checkbox
                  id="isCreditCharge3"
                  v-model="checkBillModel.isCreditCharge3"
                  @change="calCreditCharge3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>

        <!-- modal backdrop -->
        <b-modal
          id="modal-no-backdrop"
          ref="my-modal"
          centered
          size="xl"
          hide-backdrop
          hide-header-close
          no-close-on-backdrop
          content-class="shadow"
          ok-title="Accept"
          cancel-variant="outline-secondary"
          @ok="handleOkCustomer"
          @hidden="resetModal"
        >
          <b-row class="d-flex align-items-center justify-content-end">
            <b-col
              cols="5"
              class="my-1"
            >
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table
                responsive="sm"
                selectable
                :select-mode="selectMode"
                :per-page="perPage"
                :current-page="currentPage"
                :items="customers"
                :fields="customerColumns"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                @row-selected="onRowSelected"
              >
                <template #cell(selected)="{ rowSelected }">
                  <template v-if="rowSelected">
                    <i class="feather icon-disc primary" />
                  </template>

                  <template v-else>
                    <i class="feather icon-circle" />
                  </template>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="3">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="9"
              class="d-flex align-items-center justify-content-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-modal>

        <!-- modal vertical center -->
        <b-modal
          id="modal-ReportOrderSum"
          centered
          title="ReportOrderSum"
          ok-only
          ok-title="พิมพ์"
          @ok="callPrint('printReportOrderSum',value)"
        >
          <div>
            <label for="example-datepicker">Choose a date</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="value"
              class="mb-1"
            />
            <p>Value: '{{ value }}'</p>
          </div>
        </b-modal>

        <!-- modal vertical center -->
        <b-modal
          id="modal-ReportItemSum"
          centered
          title="ReportItemSum"
          ok-only
          ok-title="พิมพ์"
          @ok="callPrint('printReportItemSum',value)"
        >
          <div>
            <label for="example-datepicker">Choose a date</label>
            <b-form-datepicker
              id="example-datepicker"
              v-model="value"
              class="mb-1"
            />
            <p>Value: '{{ value }}'</p>
          </div>
        </b-modal>
        <footer class="footer text-center">

          <!-- <b-button
            v-if="order_status != 'completed'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="getPrintOrder()"
          > -->
          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="getPrintOrder()"
          >
            <feather-icon
              icon="CoffeeIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Print Order
            </p>
          </b-button>

          <!-- <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <feather-icon
                icon="InboxIcon"
                class="icon-size-24"
              />
              <p class="mt-1">
                ลิ้นชัก
              </p>
            </b-button> -->
          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-discount
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
          >
            <feather-icon
              icon="GiftIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Discount
            </p>
          </b-button>
          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="printReceipt('Checker')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Print Checker
            </p>
          </b-button>

          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="printReceiptSum('Checker')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Print Checker (Sum)
            </p>
          </b-button>
          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-checkbill
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
          >
            <feather-icon
              icon="CreditCardIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Check Bill
            </p>
          </b-button>
          <!-- <b-button
            v-if="order_status == 'completed'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="printReceiptSum('Cash')"
          > -->
          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="printReceipt('Cash')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Print Bill
            </p>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="printReceiptSum('Cash')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Print Bill (Sum)
            </p>
          </b-button>
          <b-button

            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
            @click="closeTable"
          >
            <feather-icon
              icon="XIcon"
              class="icon-size-24"
            />
            <p class="mt-1">
              Close Order
            </p>
          </b-button>
          <!--  <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-ReportOrderSum
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <p class="mt-1">
                Order Summary
              </p>
            </b-button>
             <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
               v-b-modal.modal-ReportItemSum
              variant="outline-secondary"
              class="btn-icon mr-1 mt-1"
            >
              <p class="mt-1">
                Order Detail
              </p>
            </b-button> -->

        </footer>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BCardTitle, BCardBody, BCardFooter,
  BLink, BFormCheckbox,
  BModal, BButton, VBModal, BFormInput, BFormGroup, BFormSpinbutton,
  BInputGroup, BButtonGroup, BFormRadioGroup, BInputGroupAppend, BInputGroupPrepend,
  BTable, BPagination, BFormSelect,
  BFormDatepicker,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import { heightTransition } from '@core/mixins/ui/transition'
import { stringToNumber } from '@/common'
import { getUserData } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import OrderCompPaneLeft from './order_comp_pane_left.vue'
import orderingStoreModule from './orderingStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardBody,
    BCardFooter,
    BLink,
    BModal,
    BButton,
    VBModal,
    BFormInput,
    BFormGroup,
    BFormSpinbutton,
    BFormCheckbox,
    BInputGroup,
    BButtonGroup,
    BFormRadioGroup,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroupPrepend,
    Cleave,
    vSelect,
    ToastificationContent,
    BFormDatepicker,
    VuePerfectScrollbar,
    OrderCompPaneLeft,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      tableName: router.currentRoute.params.name,
      orderNumber: router.currentRoute.params.order_number,
      billNo: '',
      billDateTime: '',
      customer: '',
      orderId: 0,
      value: '',
      printerName: 'Cashier',
      order_status: '',
      menuModel: {
        id: 0,
        menuId: 0,
        menuName: '',
        picture: '',
        qty: 0,
        attributeOption: [],
        attributeSelect: '',
        addOnOption: [],
        addOnSelect: [],
        addOns: [],
        prices: [],
        price: 0,
        specifyPrice: '',
        sc_value: 0.1,
        vat_value: 0.07,
        totPrice: 0,
        discount: 0,
        remark: '',
        isTakeHome: false,
        isFollowUp: false,
        flag_promo: 0,
        printer_id: 0,
      },
      addOnModel: {
        addOnSelect: null,
        qty: 1,
        prices: [
          { value: 0, text: 10 },
          { value: 1, text: 20 },
          { value: 2, text: 30 },
          { value: 3, text: 40 },
        ],
        price: 0,
        specifyPrice: '',
        totPrice: 0,
      },
      discountModel: {
        voucherSelect: '',
        voucherQty: 1,
        discountSelect1: '',
        discountSelect2: '',
        discountSelect3: '',
        isNotDiscountPromotion: true,
      },
      checkBillModel: {
        paymentMethodSelect1: '',
        amount1: 0,
        changed1: 0,
        isCredit1: false,
        isCreditCharge1: true,
        paymentMethodSelect2: '',
        amount2: 0,
        changed2: 0,
        isCredit2: false,
        isCreditCharge2: true,
        paymentMethodSelect3: '',
        amount3: 0,
        changed3: 0,
        isCredit3: false,
        isCreditCharge3: true,
      },
      orders: [],
      totalBeforeDiscount: 0,
      discount: 0,
      totalAfterDiscount: 0,
      serviceCharge: 0,
      vat: 0,
      beforeVoucher: 0,
      voucher: 0,
      grandTotal: 0,
      recieved: 0,
      changed: 0,
      totalPay: 0,
      // customer
      customers: [],
      // select row table in modal
      selectMode: 'single',
      selected: [],
      // search table in modal
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
    }
  },
  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    orders() {
      this.initTrHeight()
    },

  },
  mounted() {
    this.initTrHeight()
    // alert('mounted')
    this.getOrderInfo()
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
    window.removeEventListener('resize', this.initTrHeight)
  },
  setup() {
    // alert('setup')
    const OrderingStoreModuleName = 'ordering'

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Register module
    if (!store.hasModule(OrderingStoreModuleName)) store.registerModule(OrderingStoreModuleName, orderingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OrderingStoreModuleName)) store.unregisterModule(OrderingStoreModuleName)
    })

    const userData = getUserData()

    const customerColumns = [
      { key: 'name', label: 'Name', sortable: true },
      { key: 'surname', label: 'Surname', sortable: true },
      { key: 'nick_name', label: 'Nick Name', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'gender', label: 'Gender', sortable: true },
      { key: 'phone', label: 'Phone No.', sortable: true },
    ]

    const attributeOption1 = [
      { value: 'เผ็ดน้อย', label: 'เผ็ดน้อย' },
      { value: 'เผ็ดมาก', label: 'เผ็ดมาก' },
      { value: 'ไม่ใส่ผงชูรส', label: 'ไม่ใส่ผงชูรส' },
    ]

    const addOnOption = [
      { value: 'เพิ่มไข่ดาว', label: 'เพิ่มไข่ดาว' },
      { value: 'เพิ่มไข่เจียว', label: 'เพิ่มไข่เจียว' },
      { value: 'เพิ่มข้าว', label: 'เพิ่มข้าว' },
    ]

    const menuTypes = ref([
      // {
      //   id: 1000,
      //   name: 'เมนูแนะนำ',
      //   picture: require('@/assets/images/1.jpeg'),
      //   menuGroups: [
      //     // {
      //     //   id: 1,
      //     //   name: 'ข้าว',
      //     //   menus: [
      //     //     {
      //     //       id: 1,
      //     //       name: 'ข้าวผัดไก่',
      //     //       picture: require('@/assets/images/01.jpeg'),
      //     //       attributeOption: attributeOption1,
      //     //       addOnOption: addOnOption1,
      //     //       prices: [
      //     //         { value: 50, text: '50' },
      //     //         { value: 60, text: '60' },
      //     //         { value: 70, text: '70' },
      //     //       ],
      //     //     },
      //     //     {
      //     //       id: 2,
      //     //       name: 'ข้าวผัดหมู',
      //     //       picture: require('@/assets/images/02.jpeg'),
      //     //       attributeOption: attributeOption1,
      //     //       addOnOption: addOnOption1,
      //     //       prices: [
      //     //         { value: 60, text: '60' },
      //     //         { value: 70, text: '70' },
      //     //         { value: 80, text: '80' },
      //     //       ],
      //     //     },
      //     //     {
      //     //       id: 3,
      //     //       name: 'ข้าวราดกะเพราไก่',
      //     //       picture: require('@/assets/images/03.jpeg'),
      //     //       attributeOption: attributeOption1,
      //     //       addOnOption: addOnOption1,
      //     //       prices: [
      //     //         { value: 50, text: '50' },
      //     //         { value: 60, text: '60' },
      //     //         { value: 70, text: '70' },
      //     //       ],
      //     //     },
      //     //   ],
      //     // },
      //     // {
      //     //   id: 2,
      //     //   name: 'เส้น',
      //     //   menus: [
      //     //     {
      //     //       id: 1,
      //     //       name: 'ก๋วยเตี๋ยวคั่วไก่',
      //     //       picture: require('@/assets/images/04.jpeg'),
      //     //       attributeOption: attributeOption2,
      //     //       addOnOption: addOnOption2,
      //     //       prices: [
      //     //         { value: 70, text: '70' },
      //     //         { value: 80, text: '80' },
      //     //         { value: 90, text: '90' },
      //     //       ],
      //     //     },
      //     //     {
      //     //       id: 2,
      //     //       name: 'ผัดไทย',
      //     //       picture: require('@/assets/images/05.jpeg'),
      //     //       attributeOption: attributeOption2,
      //     //       addOnOption: addOnOption2,
      //     //       prices: [
      //     //         { value: 80, text: '80' },
      //     //         { value: 90, text: '90' },
      //     //         { value: 100, text: '100' },
      //     //       ],
      //     //     },
      //     //   ],
      //     // },
      //   ],
      // },
      // {
      //   id: 2,
      //   name: 'เมนูอาหาร',
      //   picture: require('@/assets/images/2.jpeg'),
      //   menuGroups: [],
      // },
      // {
      //   id: 3,
      //   name: 'เมนูเครื่องดื่ม',
      //   picture: require('@/assets/images/3.jpeg'),
      //   menuGroups: [],
      // },
      // {
      //   id: 4,
      //   name: 'โปรโมชั่น',
      //   picture: require('@/assets/images/4.jpeg'),
      //   menuGroups: [],
      // },
    ])

    const voucherOption = ref([])

    store.dispatch('ordering/getVoucher', { id: userData.branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            voucherOption.value.push({
              id: data.id,
              value: data.voucher_value,
              label: data.voucher_name,
            })
          })
        }
      })
      .catch(error => {

      })

    const discountOption1 = ref([])

    store.dispatch('ordering/getDiscount', { id: userData.branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            discountOption1.value.push({
              id: data.id,
              value: data.discount_value,
              label: data.discount_name,
            })
          })
        }
      })
      .catch(error => {

      })

    const discountOption2 = ref([])

    store.dispatch('ordering/getDiscount', { id: userData.branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            discountOption2.value.push({
              id: data.id,
              value: data.discount_value,
              label: data.discount_name,
            })
          })
        }
      })
      .catch(error => {

      })

    const discountOption3 = ref([])

    store.dispatch('ordering/getDiscount', { id: userData.branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            discountOption3.value.push({
              id: data.id,
              value: data.discount_value,
              label: data.discount_name,
            })
          })
        }
      })
      .catch(error => {

      })

    const paymentMethodOption = ref([])

    store.dispatch('ordering/getPaymentMethod', { id: userData.branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            paymentMethodOption.value.push({
              id: data.id,
              value: data.special_charge,
              label: data.payment_method,
            })
          })
        }
      })
      .catch(error => {
      })

    const atttributeLoad = ref([])

    store.dispatch('ordering/getMenuGroups', { id: userData.branchId })
      .then(response => {
        // console.log('response.data', response.data)
        if (response !== null) {
          response.data.forEach(menuGroup => {
            menuTypes.value.push({
              id: menuGroup.id,
              name: menuGroup.menu_group,
              seq: menuGroup.menu_group_seq,
              // picture: require('@/assets/images/slider/03.jpg'),
              menuGroups: [],
            })
          })

          store.dispatch('ordering/getMenus', { id: userData.branchId, table_id: router.currentRoute.params.id })
            .then(response2 => {
              console.log('Menus', response2.data)
              if (response2 !== null) {
                menuTypes.value.forEach(menuType => {
                  const menus = response2.data.filter(item => item.menu_group_id === menuType.id)
                  const menuSubs = []
                  const map = new Map()

                  menus.forEach(item => {
                    if (!map.has(item.menu_sub_id)) {
                      map.set(item.menu_sub_id, true)
                      menuSubs.push({
                        menu_group_id: item.menu_group_id,
                        menu_sub_id: item.menu_sub_id,
                        menu_sub: item.menu_sub,
                      })
                    }
                  })

                  menuSubs.forEach(menuSub => {
                    // console.log('MenuSub', menuSub)
                    menuType.menuGroups.push({
                      id: menuSub.menu_sub_id,
                      name: menuSub.menu_sub,
                      menus: [],
                    })
                  })
                })

                menuTypes.value.forEach(menuType => {
                  menuType.menuGroups.forEach(menuGroup => {
                    const menus = response2.data.filter(item => item.menu_group_id === menuType.id && item.menu_sub_id === menuGroup.id)

                    // const attlist = menus[0].fnb_Attribute_List
                    // console.log('Attribute', attlist)
                    // attlist.forEach(list => {
                    //   menus.att_list.push({
                    //     value: list.id,
                    //     label: list.attribute,
                    //   })
                    // })

                    menus.forEach(menu => {
                      // menu.forEach(attlist => {
                      //   console.log('attlists', menu.fnb_Attribute_Lists)
                      // })
                      // console.log('MenuLog', menu) //fnb_Add_On_Lists, fnb_Attribute_Lists
                      menuGroup.menus.push({
                        id: menu.menu_id,
                        name: menu.menu_name,
                        // picture: require('@/assets/images/slider/03.jpg'),)
                        attributeOption: menu.fnb_Attribute_Lists,
                        addOnOption: menu.fnb_Add_On_Lists,
                        price: 0,
                        sc_value: menu.default_sc,
                        vat_value: menu.default_vat,
                        flag_promo: menu.flag_promo,
                        printer_id: menu.printer_id,
                        prices: [
                          { value: 0, text: menu.price1 },
                          { value: 1, text: menu.price2 },
                          { value: 2, text: menu.price3 },
                          { value: 3, text: menu.price4 },
                        ],
                      })
                    })
                  })
                })
              }
            })
            .catch(error => {

            })
        }
      })
      .catch(error => {

      })

    // const menuGroups = ref(menuTypes.value[0].menuGroups)
    const menuGroups = ref([])

    return {
      customerColumns,
      menuTypes,
      menuGroups,
      attributeOption1,
      addOnOption,
      voucherOption,
      discountOption1,
      discountOption2,
      discountOption3,
      paymentMethodOption,
      userData,
      perfectScrollbarSettings,
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    setMenuGroup(menuGroups) {
      this.menuGroups = menuGroups
    },
    openMenuModal(menuItem) {
      this.menuModel = this.newMenu(menuItem)
    },
    newMenu(menuItem) {
      return {
        id: 0,
        menuId: menuItem === null ? '' : menuItem.id,
        menuName: menuItem === null ? '' : menuItem.name,
        // picture: menuItem === null ? '' : menuItem.picture,
        qty: 1,
        attributeOption: menuItem === null ? [] : menuItem.attributeOption,
        attributeSelect: '',
        addOnOption: [],
        addOnSelect: [],
        addOns: [],
        prices: menuItem === null ? [] : menuItem.prices,
        price: menuItem === null ? 0 : menuItem.price,
        specifyPrice: '',
        sc_value: menuItem.sc_value,
        vat_value: menuItem.vat_value,
        flag_promo: menuItem.flag_promo,
        printer_id: menuItem.printer_id,
        totPrice: 0,
        discount: 0,
        remark: '',
        isTakeHome: false,
        isFollowUp: false,
      }
    },
    addOrderItem(bvModalEvt) {
      this.$refs.form.style.overflow = 'hidden'

      const AddOnInsert_List = {
        order_item_id: this.id,
        add_on_id: this.add_on_id,
        quantity: 0,
        price: 0,
        created_by: 1,
      }

      const order = {
        order_number: this.billNo,
        menu_item_id: this.menuModel.menuId,
        quantity: this.menuModel.qty,
        price: this.menuModel.specifyPrice !== '' ? this.menuModel.specifyPrice : this.menuModel.prices[this.menuModel.price].text,
        remark: this.menuModel.remark,
        flag_take_away: this.menuModel.isTakeHome ? 1 : 0,
        flag_vat: 0,
        flag_sc: 0,
        vat_value: this.menuModel.vat_value,
        sc_value: this.menuModel.sc_value,
        flag_exc_dc_promo: this.menuModel.flag_promo,
        dc_special_value: stringToNumber(this.menuModel.discount),
        dc_group_id: 0,
        printer_id: this.menuModel.printer_id,
        created_by: 0,
        updated_by: 0,
        addOnInsert: [],
        attributeInsert: [],
      }

      console.log('insertOrderItem order', order)

      store.dispatch('ordering/insertOrderItem', order)
        .then(response => {
          if (response !== null) {
            this.getOrderInfo()
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    getOrderInfo() {
      store.dispatch('ordering/getOrderInfo', { id: router.currentRoute.params.id, order_number: router.currentRoute.params.order_number })
        .then(res => {
          if (res !== null) {
            console.log('getOrderInfo', res.data)
            // console.log('getOrderInfo', JSON.stringify(res.data))

            if (res.data.length === 0) { // New Oder
              const orderHead = {
                branch_id: this.userData.branchId,
                order_type: 'table',
                table_id: router.currentRoute.params.id,
              }

              store.dispatch('ordering/newOrder', orderHead)
                .then(res2 => {
                  if (res2 !== null) {
                    store.dispatch('ordering/getOrderInfo', { id: router.currentRoute.params.id, order_number: router.currentRoute.params.order_number })
                      .then(res3 => {
                        if (res3 !== null) {
                          this.billNo = res3.data[0].order_number
                          this.billDateTime = res3.data[0].created_datetime
                          this.orderId = res.data[0].order_id
                          this.order_status = res.data[0].order_status
                        }
                      })
                      .catch(error => {

                      })
                  }
                })
                .catch(error => {

                })
            } else { // Get Order
              this.billNo = res.data[0].order_number
              this.billDateTime = res.data[0].created_datetime
              this.orderId = res.data[0].order_id
              this.customer = res.data[0].customer_name
              this.order_status = res.data[0].order_status

              this.totalBeforeDiscount = res.data[0].total_before_dc === null ? 0 : res.data[0].total_before_dc
              this.discount = res.data[0].total_dc === null ? 0 : res.data[0].total_dc
              this.totalAfterDiscount = res.data[0].total_after_dc === null ? 0 : res.data[0].total_after_dc
              this.serviceCharge = res.data[0].total_sc === null ? 0 : res.data[0].total_sc
              this.vat = res.data[0].total_vat === null ? 0 : res.data[0].total_vat
              this.beforeVoucher = res.data[0].total_before_voucher === null ? 0 : res.data[0].total_before_voucher
              this.voucher = res.data[0].dc_voucher_value === null ? 0 : res.data[0].dc_voucher_value
              this.grandTotal = res.data[0].total_order_amount === null ? 0 : res.data[0].total_order_amount

              this.totalPay = this.grandTotal

              this.orders = []

              res.data[0].order_detail_list.forEach(item => {
                const order = {
                  id: item.id,
                  menuName: item.menu,
                  qty: item.quantity,
                  attributeOption: [],
                  attributeSelect: this.menuModel.attributeSelect,
                  addOnOption: [],
                  addOnSelect: this.menuModel.addOnSelect,
                  addOns: this.menuModel.addOns,
                  prices: [],
                  price: item.price,
                  specifyPrice: item.specifyPrice,
                  totPrice: item.total_price,
                  discount: item.discount,
                  remark: item.remark,
                  isTakeHome: item.isTakeHome === 1,
                  isFollowUp: item.flag_follow_up === 1,
                }

                this.orders.push(order)

                this.$nextTick(() => {
                  this.trAddHeight(this.$refs.row[0].offsetHeight)
                  setTimeout(() => {
                    this.$refs.form.style.overflow = null
                  }, 350)
                })
              })

              console.log('orders', this.orders)
            }
          }
        }, this)
        .catch(error => {

        })
    },
    cancelOrder() {
      store.dispatch('ordering/cancelOrder', { orderNumber: this.billNo })
        .then(response => {
          console.log('cancelOrder response', response)
          if (response !== null) {
            this.showToast('success', 'Cancel Order successfully.')
            router.push({ name: 'order-home' })
          }
        })
        .catch(error => {

        })
    },
    updateCustomer() {
      store.dispatch('ordering/updateCustomer', { OrderId: this.orderId, Customer: this.customer })
        .then(response => {
          console.log('updateCustomer response', response)
          if (response !== null) {
            this.showToast('success', 'Update customer successfully.')
            this.getOrderInfo()
          }
        })
        .catch(error => {

        })
    },
    updateDiscount() {
      const discountModel = {
        id: this.orderId,
        dc_voucher_id: this.discountModel.voucherSelect !== '' ? this.discountModel.voucherSelect.id : 0,
        dc_voucher_qty: this.discountModel.voucherQty !== '' ? this.discountModel.voucherQty : 0,
        dc_voucher_value: this.discountModel.voucherSelect !== '' ? this.discountModel.voucherSelect.value : 0,
        dc_percent1_id: this.discountModel.discountSelect1 !== '' ? this.discountModel.discountSelect1.id : 0,
        dc_percent2_id: this.discountModel.discountSelect2 !== '' ? this.discountModel.discountSelect2.id : 0,
        dc_percent3_id: this.discountModel.discountSelect3 !== '' ? this.discountModel.discountSelect3.id : 0,
        dc_percent1_value: this.discountModel.discountSelect1 !== '' ? this.discountModel.discountSelect1.value : 0,
        dc_percent2_value: this.discountModel.discountSelect2 !== '' ? this.discountModel.discountSelect2.value : 0,
        dc_percent3_value: this.discountModel.discountSelect3 !== '' ? this.discountModel.discountSelect3.value : 0,
        flag_dc_exc_promotion: this.discountModel.isNotDiscountPromotion ? 1 : 0,
      }

      console.log('discountModel', discountModel)

      store.dispatch('ordering/updateDiscount', discountModel)
        .then(response => {
          console.log('updateDiscount response', response)
          if (response !== null) {
            this.showToast('success', 'Update discount successfully.')
            this.getOrderInfo()
          }
        })
        .catch(error => {

        })
    },
    updatePayment() {
      const paymentModel = {
        id: this.orderId,
        order_status: '',
        payment1_type_id: this.checkBillModel.paymentMethodSelect1 !== '' ? this.checkBillModel.paymentMethodSelect1.id : 0,
        payment2_type_id: this.checkBillModel.paymentMethodSelect2 !== '' ? this.checkBillModel.paymentMethodSelect2.id : 0,
        payment3_type_id: this.checkBillModel.paymentMethodSelect3 !== '' ? this.checkBillModel.paymentMethodSelect3.id : 0,
        payment1_amount: stringToNumber(this.checkBillModel.amount1),
        payment2_amount: stringToNumber(this.checkBillModel.amount2),
        payment3_amount: stringToNumber(this.checkBillModel.amount3),
        payment1_change: stringToNumber(this.checkBillModel.changed1),
        payment2_change: stringToNumber(this.checkBillModel.changed2),
        payment3_change: stringToNumber(this.checkBillModel.changed3),
        total_payment: stringToNumber(this.checkBillModel.amount1) + stringToNumber(this.checkBillModel.amount2) + stringToNumber(this.checkBillModel.amount3),
      }

      this.recieved = paymentModel.total_payment
      this.changed = paymentModel.payment1_change + paymentModel.payment2_change + paymentModel.payment3_change

      console.log('paymentModel', paymentModel)

      store.dispatch('ordering/updatePayment', paymentModel)
        .then(response => {
          console.log('updatePayment response', response)
          if (response !== null) {
            this.showToast('success', 'Update payment successfully.')
            this.getOrderInfo()
          }
        })
        .catch(error => {

        })
    },
    // calBill() {
    //   this.totalBeforeDiscount = 0
    //   this.discount = 0

    //   this.orders.forEach(order => {
    //     this.totalBeforeDiscount += order.totPrice
    //     this.discount += stringToNumber(order.discount)
    //   })

    //   this.serviceCharge = stringToNumber((this.totalBeforeDiscount * 0.10).toFixed(2))

    //   this.vat = stringToNumber((this.totalBeforeDiscount * 0.07).toFixed(2))

    //   const voucher = this.discountModel.voucherSelect !== '' ? this.discountModel.voucherSelect.value : 0
    //   const discount1 = this.discountModel.discountSelect1 !== '' ? this.totalBeforeDiscount * (this.discountModel.discountSelect1.value / 100) : 0
    //   this.discount = voucher + discount1

    //   this.totalAfterDiscount = this.totalBeforeDiscount - this.discount
    //   this.grandTotal = this.totalAfterDiscount + this.serviceCharge + this.vat
    //   this.grandTotal = this.totalAfterDiscount
    // },
    // calCheckBill() {
    //   this.recieved = stringToNumber(this.checkBillModel.amount)
    //   this.changed = this.recieved - this.grandTotal - stringToNumber(this.checkBillModel.creditChargeAmount)
    // },
    followOrderItem(item) {
      store.dispatch('ordering/followOrderItem', { id: item.id, order_number: this.billNo })
        .then(response => {
          console.log('FollowUpItem response', response)
          if (response !== null) {
            this.showToast('success', 'Follow up order item successfully.')
            this.getOrderInfo()
          }
        })
        .catch(error => {

        })
    },
    removeItem(index, item) {
      store.dispatch('ordering/removeOrderItem', { id: item.id, order_number: this.billNo })
        .then(response => {
          console.log('removeOrderItem response', response)
          if (response !== null) {
            this.showToast('success', 'Remove order item successfully.')
            this.getOrderInfo()
          }
        })
        .catch(error => {

        })

      // this.calBill()
      // this.calCheckBill()
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    // getPrice(order) {
    //   return order.price === 0 ? stringToNumber(order.specifyPrice) : order.price
    // },
    FormatNumber(number) {
      const str_num = number.toString().split('.')
      if (str_num.length === 1) {
        return str_num[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }
      const fp = parseFloat(`0.${str_num[1]}`)
      const fpt = fp.toFixed(2).split('.')[1]
      return `${str_num[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}.${fpt}`
    },

    getAttribute(attributes) {
      let result = ''
      if (attributes !== '') {
        attributes.forEach(attribute => {
          result += `${attribute.label} `
        })
      }
      return result
    },
    handleOkAddOn() {
      if (this.addOnModel.addOnSelect !== null) {
        this.menuModel.addOnSelect.push({
          value: this.addOnModel.addOnSelect.value, label: `${this.addOnModel.addOnSelect.value}x${this.addOnModel.qty}`,
        })

        this.menuModel.addOns.push({
          id: this.addOnModel.addOnSelect.value,
          name: this.addOnModel.addOnSelect.value,
          price: this.addOnModel.prices[this.addOnModel.price].text,
          qty: this.addOnModel.qty,
        })
      }

      this.addOnModel.addOnSelect = null
      this.addOnModel.price = 0
      this.addOnModel.qty = 1
    },
    setCreditCharge1(selected) {
      if (selected !== null && selected.label === 'Credit Card') {
        this.checkBillModel.isCredit1 = true
        this.calCreditCharge1()
      } else {
        this.checkBillModel.isCredit1 = false
      }
    },
    setCreditCharge2(selected) {
      if (selected !== null && selected.label === 'Credit Card') {
        this.checkBillModel.isCredit2 = true
        this.calCreditCharge2()
      } else {
        this.checkBillModel.isCredit2 = false
      }
    },
    setCreditCharge3(selected) {
      if (selected !== null && selected.label === 'Credit Card') {
        this.checkBillModel.isCredit3 = true
        this.calCreditCharge3()
      } else {
        this.checkBillModel.isCredit3 = false
      }
    },
    calCreditCharge1() {
      this.totalPay = this.checkBillModel.isCreditCharge1 ? this.grandTotal - stringToNumber((this.grandTotal * 0.03).toFixed(2)) : this.grandTotal
      this.calChanged1()
    },
    calCreditCharge2() {
      const amount = this.totalPay - stringToNumber(this.checkBillModel.amount1)
      this.totalPay = this.checkBillModel.isCreditCharge2 ? this.totalPay + stringToNumber((amount * 0.03).toFixed(2)) : this.totalPay
      this.calChanged2()
    },
    calCreditCharge3() {
      const amount = this.totalPay - stringToNumber(this.checkBillModel.amount1) - stringToNumber(this.checkBillModel.amount2)
      this.totalPay = this.checkBillModel.isCreditCharge3 ? this.totalPay + stringToNumber((amount * 0.03).toFixed(2)) : this.totalPay
      this.calChanged3()
    },
    calChanged1() {
      const recieved = stringToNumber(this.checkBillModel.amount1) - this.totalPay
      this.checkBillModel.changed1 = recieved > 0 ? recieved : 0
    },
    calChanged2() {
      const recieved = (stringToNumber(this.checkBillModel.amount1) + stringToNumber(this.checkBillModel.amount2)) - this.totalPay
      this.checkBillModel.changed2 = recieved > 0 ? recieved : 0
    },
    calChanged3() {
      const recieved = (stringToNumber(this.checkBillModel.amount1) + stringToNumber(this.checkBillModel.amount2) + stringToNumber(this.checkBillModel.amount3)) - this.totalPay
      this.checkBillModel.changed3 = recieved > 0 ? recieved : 0
    },
    searchCustomer() {
      store.dispatch('ordering/searchCustomer', { query: '' })
        .then(response => {
          // console.log('response.data', response.data)
          if (response !== null) {
            this.customers = response.data

            // Set the initial number of items
            this.totalRows = this.customers.length

            this.$refs['my-modal'].show()
          }
        })
        .catch(error => {

        })
    },
    handleOkCustomer(bvModalEvt) {
      if (this.selected.length === 0) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()

        this.$swal({
          title: 'Warning!',
          text: ' Please select data !',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

      this.customer = `${this.selected[0].name} ${this.selected[0].surname}`
    },
    onRowSelected(item) {
      this.selected = item
    },
    resetModal() {
      this.selected = []
      this.customers = []
      this.filter = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
    // printReceipt() {
    //   const obj = {
    //     printerName: 'Cashire',
    //     mode: 'Checker',
    //     taxID: '-',
    //     machineNo: '-',
    //     reference: '-',
    //     table: data.order_type,
    //     name: data.order_number,
    //     ref: '',
    //     detail: details,
    //     discount: data.total_dc,
    //     subTotal: 0,
    //     sC10Per: data.total_sc,
    //     vat7Per: data.total_vat,
    //     beforeVat:data.total_before_dc,
    //     total: data.total_before_dc,
    //     grandTotal: data.total_order_amount,
    //     cash: 0,
    //   }

    //   console.log('obj', obj)

    //   store.dispatch('ordering/printReceipt', obj)
    //     .then(response => {
    //       console.log('response', response)
    //     })
    //     .catch(error => {
    //       console.log('error', error)
    //     })
    // },
    async printReceipt(printtype) {
      console.log('this.orders', this.orders)
      let data = {
        order_id: 12,
        order_number: '220728-00007',
        branch_name: 'Kilik',
        branch_full_name: 'Kilikllllll',
        address1: '12/12 St.Phayothin',
        address2: 'Din Dang, BKK, 10400',
        tax_id: '1234567890',
        order_type: 'table',
        order_status: 'pending',
        table_id: 16,
        table_name: 'A1',
        order_remark: null,
        customer_remark: null,
        member_id: null,
        total_before_dc: 7190,
        total_dc: null,
        total_sc: 0,
        total_vat: 0,
        dc_voucher_value: 0,
        total_before_round: 7190,
        total_before_voucher: 7190,
        total_order_amount: 7190,
        order_detail_list: [{
          id: 1, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 240, menu: 'Glenfidich 15 Year', quantity: 1, price: 6200, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 2, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 272, menu: 'Coke', quantity: 1, price: 70, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 3, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 284, menu: 'Ice (Small)', quantity: 2, price: 80, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 4, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 274, menu: 'Water', quantity: 1, price: 60, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 15, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 287, menu: 'Butterfly Effect', quantity: 1, price: 350, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 16, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 284, menu: 'Ice (Small)', quantity: 1, price: 80, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 66, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 287, menu: 'Butterfly Effect', quantity: 1, price: 350, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }],
      }

      console.log('11111')

      data = await store.dispatch('ordering/getOrderInfo', { id: router.currentRoute.params.id, order_number: router.currentRoute.params.order_number })
        .then(res => {
          console.log('getOrderInfo', res)
          console.log(JSON.stringify(res.data))

          const data2 = res.data[0]

          console.log('data2', data2)
          console.log('order_detail_list', data2.order_detail_list)

          return data2
        })
        .catch(error => null)

      const details = data.order_detail_list.map(obj => ({
        ...obj,
        name: obj.menu,
        qty: obj.quantity,
        price: obj.price,
        total: obj.total_price,
      }))

      const obj = {
        printerName: this.printerName,
        //  printerName : 'TomCashier',
        mode: printtype,
        // mode: 'Checker',
        taxID: data.tax_id,
        machineNo: '-',
        reference: '-',
        branch_name: data.branch_name,
        branch_fullname: data.branch_full_name,
        address1: data.address1,
        address2: data.address2,
        branch_app_ip: data.branch_app_ip,
        table: data.table_name,
        name: '-',
        ref: data.customer_remark,
        detail: details,
        discount: data.total_dc,
        subTotal: data.total_after_dc,
        sC10Per: data.total_sc,
        vat7Per: data.total_vat,
        beforeVat: data.total_before_dc,
        total: data.total_before_dc,
        beforeVoucher: data.total_before_voucher,
        voucher: data.dc_voucher_value,
        grandTotal: data.total_order_amount,
        cash: data.total_payment !== null ? data.total_payment : 0,
      }

      console.log('obj', obj)

      store.dispatch('ordering/printReceipt', obj)
        .then(response => {
          console.log('response', response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    async printReceiptSum(printtype) {
      console.log('this.orders', this.orders)
      let data = {
        order_id: 12,
        order_number: '220728-00007',
        branch_name: 'Kilik',
        branch_full_name: 'Kilikllllll',
        address1: '12/12 St.Phayothin',
        address2: 'Din Dang, BKK, 10400',
        tax_id: '1234567890',
        order_type: 'table',
        order_status: 'pending',
        table_id: 16,
        table_name: 'A1',
        order_remark: null,
        customer_remark: null,
        member_id: null,
        total_before_dc: 7190,
        total_dc: null,
        total_sc: 0,
        total_vat: 0,
        dc_voucher_value: 0,
        total_before_round: 7190,
        total_before_voucher: 7190,
        total_order_amount: 7190,
        order_detail_sum: [{
          id: 1, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 240, menu: 'Glenfidich 15 Year', quantity: 1, price: 6200, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 2, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 272, menu: 'Coke', quantity: 1, price: 70, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 3, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 284, menu: 'Ice (Small)', quantity: 2, price: 80, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 4, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 274, menu: 'Water', quantity: 1, price: 60, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 15, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 287, menu: 'Butterfly Effect', quantity: 1, price: 350, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 16, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 284, menu: 'Ice (Small)', quantity: 1, price: 80, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }, {
          id: 66, order_number: '220728-00007', order_item_status: 'ordered', menu_item_id: 287, menu: 'Butterfly Effect', quantity: 1, price: 350, remark: '', flag_take_away: 0, flag_follow_up: null, flag_vat: 0, flag_sc: 0, flag_exc_dc_promo: 0, flag_exc_dc_percent: null, dc_special_value: 0, dc_group_id: 0, printer_id: 0,
        }],
      }

      console.log('11112')

      data = await store.dispatch('ordering/getOrderInfo', { id: router.currentRoute.params.id, order_number: router.currentRoute.params.order_number })
        .then(res => {
          console.log('getOrderInfo', res)
          console.log(JSON.stringify(res.data))

          const data2 = res.data[0]

          console.log('data2', data2)
          console.log('order_detail_list', data2.order_detail_list)
          console.log('order_detail_sum', data2.order_detail_sum)

          return data2
        })
        .catch(error => null)

      const details = data.order_detail_sum.map(obj => ({
        ...obj,
        name: obj.menu,
        qty: obj.quantity,
        price: obj.price,
        total: obj.total_price,
      }))

      const obj = {
        printerName: this.printerName,
        //  printerName : 'TomCashier',
        mode: printtype,
        // mode: 'Checker',
        taxID: data.tax_id,
        machineNo: '-',
        reference: '-',
        branch_name: data.branch_name,
        branch_fullname: data.branch_full_name,
        address1: data.address1,
        address2: data.address2,
        branch_app_ip: data.branch_app_ip,
        table: data.table_name,
        name: '-',
        ref: data.customer_remark,
        detail: details,
        discount: data.total_dc,
        subTotal: data.total_after_dc,
        sC10Per: data.total_sc,
        vat7Per: data.total_vat,
        beforeVat: data.total_before_dc,
        total: data.total_before_dc,
        beforeVoucher: data.total_before_voucher,
        voucher: data.dc_voucher_value,
        grandTotal: data.total_order_amount,
        cash: data.total_payment !== null ? data.total_payment : 0,
      }

      console.log('obj', obj)

      store.dispatch('ordering/printReceipt', obj)
        .then(response => {
          console.log('response', response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    closeTable() {
      store.dispatch('ordering/getCloseTable', { id: router.currentRoute.params.id })
        .then(response => {
          if (response !== null) {
            console.log('response', response)

            if (response.status == 200) {
              router.push({ name: 'order-home' })
            }
          }
        })
        .catch(error => {
        })
    },

    async getPrintOrder() {
      let data = {}
      console.log(this.billNo)
      data = await store.dispatch('ordering/getPrintOrder', { id: this.billNo })
        .then(response => {
          if (response !== null) {
            console.log('response', response)

            const data2 = response.data

            return data2
          }
        })
        .catch(error => null)

      console.log(data)
      const details = data.map(obj => ({

        // printerName: 'Cashier',
        printerName: obj.printer_name,
        printer_ip: obj.printer_ip,
        table: obj.table_name,
        orderBy: obj.order_number,
        orderName: obj.menu_name,
        remark: obj.remark,
        qty: obj.quantity,
        isCancel: 0,
      }))

      console.log('details', details)

      store.dispatch('ordering/printOrder', details)
        .then(response => {
          console.log('response', response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    //      async printReportOrderSum(orderdate) {

    //       let data = {}

    //       console.log('printReportOrderSum')

    //       data = await store.dispatch('ordering/getOrderReportOrderSum', { id: userData.branchId , orderdate: orderdate })
    //         .then(res => {
    //           console.log('printReportOrderSum', res.data[0])
    //           console.log(JSON.stringify(res.data))

    //           const data2 = res.data[0]

    //           // console.log('data2', data2)
    //           // console.log('order_detail_list', data2.order_detail_list)

    //           return data2

    //         })
    //         .catch(error => null)

    //       const details = data.report_order_dtl.map(obj => ({
    //         customers: obj.customer !== null ? obj.customer : '-',
    //         payment: obj.payment !== null ? obj.payment : '-',
    //         tables: obj.table !== null ? obj.table : '-',
    //         total: obj.total !== null ? obj.total : 0,
    //       }))

    //       const obj = {
    //   printerName : this.printerName,
    //   detail : details,
    //   totalBfDC : data.total_before_dc,
    //   totalDC : data.total_dc,
    //   subTotal : data.sub_total,
    //   sC10Per : data.total_sc,
    //   vat7Per : data.total_vat,
    //   grandTotal : data.total_order_amount
    // }

    //       console.log('obj', obj)

    //       store.dispatch('ordering/printOrderSum', obj)
    //         .then(response => {
    //           console.log('response', response)
    //         })
    //         .catch(error => {
    //           console.log('error', error)
    //         })
    //     },

    //       async printReportItemSum() {

    //       let data = {}

    //       console.log('printReportOrderSum')

    //       data = await store.dispatch('ordering/getReportItemSum', { id: userData.branchId , orderdate: '2022-08-04' })
    //         .then(res => {
    //           console.log('printReportOrderSum', res.data)
    //           console.log(JSON.stringify(res.data))

    //           const data2 = res.data

    //           // console.log('data2', data2)
    //           // console.log('order_detail_list', data2.order_detail_list)

    //           return data2

    //         })
    //         .catch(error => null)

    //       // const details = data.report_item_dtl.map(obj => ({
    //       //   itemService: obj.item !== null ? obj.item : 0,
    //       //   qty: obj.quantity !== null ? obj.quantity : 0,
    //       //   amt: obj.total !== null ? obj.total : 0,
    //       // }))

    //       const obj = {
    //    printerName : this.printerName,
    //   head: data
    // }

    //       console.log('obj', obj)

    //       store.dispatch('ordering/printItemSum', obj)
    //         .then(response => {
    //           console.log('response', response)
    //         })
    //         .catch(error => {
    //           console.log('error', error)
    //         })
    //     },
    //   callPrint (check,orderdate) {
    //            if(check == 'printReportOrderSum'){
    //                 this.printReportOrderSum(orderdate);
    //            } else if(check == 'printReportItemSum'){
    //                 this.printReportItemSum(orderdate);
    //            }
    //   },

  },
}

</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.scroll {
    max-height: 400px;
    overflow-y: auto;

}

.scroll2 {
    max-height: 200px;
    overflow-y: auto;

}

.scroll3 {
  //  width: 700px;
  //  height: 60px;
  // overflow-x: scroll;

}

.footer {
    bottom: 0;
    position: sticky;
    height: 100px;
    width:100%;
    background-color: white;
    margin: 0;
}

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
  line-height: 100px;
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

.icon-size-24 {
  height: 24px;
  width: 24px;
}
</style>
<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
