import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getMenuGroups(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbMenu/GetAllbyBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getMenus(ctx, { id, table_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbMenu/GetMenuList2', {
            params: {
              branch_id: id,
              table_id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderInfo(ctx, { id, order_number }) {
      if (order_number) {
        var data = {
          order_number,
        }
      } else {
        var data = {
          Table_Id: id,
        }
      }
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbOrder/GetOrderInfo', {
            params: data,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    newOrder(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbOrder/NewOrder', order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelOrder(ctx, { orderNumber }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbOrder/CancelOrder', {
            params: {
              OrderNumber: orderNumber,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    insertOrderItem(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbOrder/InsertNewItem', order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeOrderItem(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbOrder/RemoveItem', order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    followOrderItem(ctx, order) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbOrder/FollowUpItem', order)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDiscount(ctx, discount) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbOrder/UpdateDiscount', discount)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePayment(ctx, payment) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/FnbOrder/UpdatePayment', payment)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReceipt(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios
          .post(`https://${obj.branch_app_ip}/api/Report/print-receipt`, obj)
          // .post('https://192.168.1.172:5002/api/Report/print-receipt', obj)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReportVoucher(ctx, { id, orderdate }) {
      console.log(id)
      console.log(orderdate)
      return new Promise((resolve, reject) => {
        axios

          .get('https://posapi.owlstime.com/api/FnbReport/GetReportOrderSum', {
            params: {
              BranchId: id,
              OrderDate: orderdate,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printReportVoucher(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`https://192.168.1.172:5002/api/Report/print-ordering-all-table`, obj)
          .post(`https://${obj.branch_app_ip}/api/Report/print-ordering-all-table`, obj)

          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderReportSummary(ctx, { id, startDate, endDate }) {
      console.log(id)
      console.log(startDate)
      console.log(endDate)
      return new Promise((resolve, reject) => {
        axios

          .get('https://posapi.owlstime.com/api/FnbReport/GetReportSummary', {
            params: {
              BranchId: id,
              StartDate: startDate,
              EndDate: endDate,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getOrderReportOrderSum(ctx, { id, orderdate }) {
      console.log(id)
      console.log(orderdate)
      return new Promise((resolve, reject) => {
        axios

          .get('https://posapi.owlstime.com/api/FnbReport/GetReportOrderSum', {
            params: {
              BranchId: id,
              OrderDate: orderdate,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printOrderSum(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`https://192.168.1.172:5002/api/Report/print-ordering-all-table`, obj)
          .post(`https://${obj.branch_app_ip}/api/Report/print-ordering-all-table`, obj)

          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReportItemSum(ctx, { id, orderdate }) {
      console.log(id)
      console.log(orderdate)
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbReport/GetReportItemSum', {
            params: {
              BranchId: id,
              OrderDate: orderdate,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printItemSum(ctx, obj) {
      return new Promise((resolve, reject) => {
        axios
          // .post(`https://192.168.1.172:5002/api/Report/print-receipt-group-menu`, obj)
          .post(`https://${obj.branch_app_ip}/api/Report/print-receipt-group-menu`, obj)

          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCloseTable(ctx, { id }) {
      console.log(id)

      return new Promise((resolve, reject) => {
        axios

          .get('https://posapi.owlstime.com/api/FnbOrder/CloseTable', {
            params: {
              TableId: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPrintOrder(ctx, { id }) {
      console.log(id)

      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbOrder/PrintOrder', {
            params: {
              OrderNo: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printOrder(ctx, obj) {
      const ip = obj[0].printer_ip
      return new Promise((resolve, reject) => {
        axios
          //.post(`https://192.168.1.34:5002/api/Report/print-order`, obj)
          .post(`https://${ip}/api/Report/print-order`, obj)

          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchCustomer(ctx, query) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/Member/SearchMember', {
            params: {
              Name: '',
              Surname: '',
              NickName: '',
              Phone: '',
              Email: '',
              Gender: '',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, order) {
      console.log(order)
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbOrder/UpdateCustomer', {
            params: {
              OrderId: order.OrderId,
              Customer: order.Customer,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getVoucher(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/MktVoucher/GetAllbyBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDiscount(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/MktDiscount/GetAllbyBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPaymentMethod(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/Payment/GetAllbyBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
