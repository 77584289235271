<template>
  <b-card>
    <b-row class="container testimonial-group">
      <b-col>
        <b-card class="mb-1">
          <b-row class="scroll3">
            <b-col
              v-for="menuType in menuTypes"
              :key="menuType.id"
              md="3"
            >
              <b-link @click="setMenuGroup(menuType.menuGroups)">
                <b-card
                  overlay
                  body-class="bg-gradient-secondary"
                  text-variant="white"
                  class="text-center mb-1"
                  style="height: 100px; width: 100%"
                >
                  <b-card-title class="text-white">
                    {{ menuType.name }}
                  </b-card-title>
                </b-card>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="scroll">
      <b-col>
        <b-card
          v-for="menuGroup in menuGroups"
          :key="menuGroup.id"
          :title="menuGroup.name"
          class="mb-1"
        >
          <b-row>
            <b-col
              v-for="menu in menuGroup.menus"
              :key="menu.id"
              md="3"
            >
              <b-link
                v-b-modal.modal-menu-add
                @click="openMenuModal(menu)"
              >
                <b-card
                  overlay
                  body-class="bg-gradient-secondary"
                  text-variant="white"
                  class="text-center mb-1"
                  style="height: 100px; width: 100%"
                >
                  <b-card-title class="text-white">
                    {{ menu.name }}
                  </b-card-title>
                </b-card>
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

export default {
  name: 'Test',
}

</script>
